import React from 'react';

export const Home: React.FC = () => {
    return (
        <div>
            <div className="container">
                <br /><br /><br />
                <h1 className="text-center">Technology Proposals Have Moved into ServiceNow</h1>

                <p>
                <h2  className="text-center">
                    Technology Proposals have moved into ServiceNow.
                    <br/>
                    <a href="https://fdot.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=c785d80793dd8e904280f95d6cba10d3" > Click here to submit a new proposal. </a>
                    <br />
                    If you have questions about your existing proposal, contact your assigned OIT Analyst.
                    <br />
                    For general questions about Technology Proposals and the process in ServiceNow please email:
                    <a href="mailto:FDOT-TechnologyProposalSupport@dot.state.fl.us" > FDOT-TechnologyProposalSupport@dot.state.fl.us </a>
                    <br />
                    If you are experiencing issues submitting a proposal in ServiceNow, please submit a ticket to the Service Desk.
                </h2>
            </p>

                <div className="text-center">
                    <button
                        className="btn btn-primary"
                        type="button"
                        aria-label="Login"
                        onClick={() => window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/login`}>
                        Admins
                </button>
                </div>
                <br />
                <p className="text-center">
                    Please note: Access to this application is limited to Admins.
            </p>

            </div>
        </div >
    );
}